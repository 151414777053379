import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Home from './pages/Home'
import Navbar from './components/Navbar'
import Footer from './components/Footer';
import Events from './pages/Events';
import ViewProjects from './pages/ViewProjects';
import Projects from './pages/Projects';
import Reports from './pages/Reports';
import Page404 from './pages/404';
import ProjectData from './components/Projects.json'

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top:0, left:0, behavior: "instant"});
  }, [pathname]);
  return null;
}

function App() {
  const [language,setLanguage] = useState('')
  const path = useLocation().pathname.split("/")

  useEffect(() => {
    if(path[1].toLowerCase() === 'viewprojects'){
      if(isNaN(path[2])) return (<div><Navbar/><Page404/><Footer/></div>)
      const projId = parseInt(path[2])
      if(projId < 1 || projId > ProjectData.length) return (<div><Navbar/><Page404/><Footer/></div>)
    }
  },[path])

  function handleLanguageChange(lang){
    localStorage.setItem('language',lang)
    if(lang === 'English') setLanguage('English')
    if(lang === 'Spanish') setLanguage('Spanish')
    if(lang === 'Vietnamese') setLanguage('Vietnamese')
  }

  useEffect(() => {
    console.log()
    const lang = localStorage.getItem('language')
    if(lang === 'English'){
        handleLanguageChange('English')
        return
    }
    if(lang === 'Spanish'){
        handleLanguageChange('Spanish')
        return
    }
    if(lang === 'Vietnamese'){
        handleLanguageChange('Vietnamese')
        return
    }
    localStorage.setItem('language','English')
  },[])

  return (
    <div className='App'>
      <ScrollToTop/>
      <Navbar handleLanguageChange={handleLanguageChange} language={language}/>
      <Routes>
        <Route path="/" element={<Home language={language} />} />
        <Route path="/events" element={<Events language={language} />} />
        <Route path="/viewprojects/:id" element={<ViewProjects language={language} />} />
        <Route path="/projects" element={<Projects language={language} />} />
        <Route path="/reports" element={<Reports language={language} />} />
        <Route path="*" element={<Page404 language={language} />}/>
      </Routes>
      <Footer language={language}/>
    </div>
  );
}

export default App;

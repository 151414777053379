import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import headerImg from '../assets/tarrantCountySeal.png'
import * as bootstrap from 'bootstrap';
import translation from '../assets/translation.json'

export default function Navbar(props) {
    const handleLanguageChange = props.handleLanguageChange
    const language = props.language
    const [home,setHome] = useState('')
    const [events,setEvents] = useState('')
    const [projects,setProjects] = useState('')
    const [reports,setReports] = useState('')
    const location = useLocation();
    const [languageText,setLanguageText] = useState('')
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English'){
            handleLanguage('English')
            return
        }
        if(lang === 'Spanish'){
            handleLanguage('Spanish')
            return
        }
        if(lang === 'Vietnamese'){
            handleLanguage('Vietnamese')
            return
        }
        handleLanguage('English')
    },[language])

    
    useEffect(() => {
        const navLinks = document.querySelectorAll('.nav-item')
        const menuToggle = document.getElementById('navbarCollapse')
        const bsCollapse = new bootstrap.Collapse(menuToggle,{ toggle:false })
        document.getElementById('navbar').addEventListener('mouseleave',() => { bsCollapse.hide() })

        navLinks.forEach((l) => {
            l.addEventListener('click',() => {bsCollapse.hide() })
        })

        const dropdownMenu = document.querySelector('.dropdown-menu')
        const dropdown = document.getElementById('dropdown')
        const bsDropdown = new bootstrap.Dropdown(dropdown)
        dropdown.addEventListener('click',() => { bsDropdown.toggle() })
        dropdown.addEventListener('mouseleave',() => { bsDropdown.hide() })
        dropdownMenu.addEventListener('click',() => { bsDropdown.show() })
        dropdownMenu.addEventListener('mouseleave',() => { bsDropdown.hide() })

        const items = document.querySelectorAll('.dropdown-item')
        items.forEach(i => {
            i.addEventListener('click',() => { bsCollapse.hide() })
        });
    },[])

    function handleLanguage(lang){
        localStorage.setItem('language',lang)
        if(lang === 'English'){
            setLanguageText('English')
            setHome(translation.english.navbar.home)
            setEvents(translation.english.navbar.events)
            setProjects(translation.english.navbar.projects)
            setReports(translation.english.navbar.reports)
        }
        if(lang === 'Spanish'){
            setLanguageText('Español')
            setHome(translation.spanish.navbar.home)
            setEvents(translation.spanish.navbar.events)
            setProjects(translation.spanish.navbar.projects)
            setReports(translation.spanish.navbar.reports)
        }
        if(lang === 'Vietnamese'){
            setLanguageText('Tiếng Việt')
            setHome(translation.vietnamese.navbar.home)
            setEvents(translation.vietnamese.navbar.events)
            setProjects(translation.vietnamese.navbar.projects)
            setReports(translation.vietnamese.navbar.reports)
        }
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark" id="navbar" style={{backgroundColor:"#1D2856"}}>
                <div className="container mb-2">
                    <a className="navbar-brand" href="/"><img src={headerImg}
                        style={{ height: "70px" }} alt="Tarrant County"/></a>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> <span className="navbar-toggler-icon"></span> </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav fw-bold" style={{fontSize:"20px"}}>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "" ? "active" : "")} to="/">{home}</Link></li>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "events" ? "active" : "")} to="/events">{events}</Link></li>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "projects" || splitLocation[1] === "viewprojects" ? "active" : "")} to="/projects">{projects}</Link></li>
                            <li className="nav-item mx-1"> <Link className="nav-link" id={(splitLocation[1] === "reports" ? "active" : "")} to="/reports">{reports}</Link></li>
                        </ul>
                        <div className="navbar-nav ms-auto mt-1 dropdown" id='dropdown'>
                            <div className='dropdown-toggle text-white' role='button' aria-expanded="false"  data-bs-toggle="dropdown">{languageText}</div>
                            <ul className="dropdown-menu my-0">
                                <button className='dropdown-item' onClick={()=>{handleLanguageChange('English') }}>English</button>
                                <button className='dropdown-item' onClick={()=>{handleLanguageChange('Spanish') }}>Español</button>
                                <button className='dropdown-item' onClick={()=>{handleLanguageChange('Vietnamese') }}>Tiếng Việt</button>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

import React, { createRef, useEffect, useState } from 'react'
import CarImg from '../assets/drone shots/Panel Composition - 013.jpg'
import { useParams, useNavigate } from 'react-router-dom';
import Projects from '../components/Projects.json'
import Spinner from '../components/Spinner'
import translation from '../assets/translation.json'

export default function ViewProjects(props) {
    const { id } = useParams();
    const [projectData, setProjectData] = useState({})
    const [projectBody, setProjectBody] = useState('')
    const [projectStatus, setProjectStatus] = useState('')
    const [ProjectContactTitle, setProjectContactTitle] = useState('')
    const [precinctId, setPrecinctId] = useState('')
    const [precinct, setPrecinct] = useState('')
    const [countyFunding, setCountyFunding] = useState('')
    const [countyFundingTitle, setCountyFundingTitle] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [endDate, setEndDate] = useState()
    const navigate = useNavigate();
    const ref = createRef();

    const [estimatedCompletion, setEstimatedCompletion] = useState('')
    const [status, setStatus] = useState('')
    const [projectContact, setProjectContact] = useState('')
    const [backButton, setBackButton] = useState('')
    const [notFound, setNotFound] = useState()

    const [images, setImages] = useState([])
    const [imageButtons, setImageButtons] = useState([])

    useEffect(() => {
        var data = null;
        var _images = []
        var _imageButtons = []

        for (let i = 0; i < Projects.length; i++) {
            if (id === Projects[i].id) {
                data = Projects[i]
            }
        }
        if (data == null) {
            setError(true)
        }
        setProjectData(data)

        var tempDate = ""

        const lang = localStorage.getItem('language')
        if (lang === 'English') {
            setProjectBody(data.english.body)
            setProjectStatus(data.english.status)
            setProjectContactTitle(data.english.contactTitle)
            setPrecinctId(data.precinctId)
            setCountyFunding(data.countyFunding)

            setEstimatedCompletion(translation.english.viewProject.estimatedCompletion)
            setStatus(translation.english.viewProject.projectStatus)
            setProjectContact(translation.english.viewProject.projectContact)
            setBackButton(translation.english.viewProject.backButton)
            setNotFound(translation.english.viewProject.notFound)

            if (data.endDate == "To Be Determined") {
                tempDate = "To Be Determined"
            } 
            else if (data.endDate == "Pending TxDOT Construction") {
                tempDate = "Pending TxDOT Construction"
            }
            else {
                tempDate = new Date(data.endDate.replace(/-/g, "/")).toLocaleDateString("en-US", { month: "long", year: "numeric" })
            }
            setEndDate(tempDate)

            setPrecinct(translation.english.viewProject.precinct)
            setCountyFundingTitle(translation.english.viewProject.countyFunding)
        }
        if (lang === 'Spanish') {
            setProjectBody(data.spanish.body)
            setProjectStatus(data.spanish.status)
            setProjectContactTitle(data.spanish.contactTitle)
            setPrecinctId(data.precinctId)
            setCountyFunding(data.countyFunding)

            setEstimatedCompletion(translation.spanish.viewProject.estimatedCompletion)
            setStatus(translation.spanish.viewProject.projectStatus)
            setProjectContact(translation.spanish.viewProject.projectContact)
            setBackButton(translation.spanish.viewProject.backButton)
            setNotFound(translation.spanish.viewProject.notFound)

            if (data.endDate == "To Be Determined") {
                tempDate = "Estar determinado"
            } 
            if (data.endDate == "Pending TxDOT Construction") {
                tempDate = "Construcción pendiente de TxDOT"
            }
            else {
                tempDate = new Date(data.endDate.replace(/-/g, "/")).toLocaleDateString("es", { month: "long", year: "numeric" })
            }
            setEndDate(tempDate)

            setPrecinct(translation.spanish.viewProject.precinct)
            setCountyFundingTitle(translation.spanish.viewProject.countyFunding)
        }
        if (lang === 'Vietnamese') {
            setProjectBody(data.vietnamese.body)
            setProjectStatus(data.vietnamese.status)
            setProjectContactTitle(data.vietnamese.contactTitle)
            setPrecinctId(data.precinctId)
            setCountyFunding(data.countyFunding)

            setEstimatedCompletion(translation.vietnamese.viewProject.estimatedCompletion)
            setStatus(translation.vietnamese.viewProject.projectStatus)
            setProjectContact(translation.vietnamese.viewProject.projectContact)
            setBackButton(translation.vietnamese.viewProject.backButton)
            setNotFound(translation.vietnamese.viewProject.notFound)

            if (data.endDate == "To Be Determined") {
                tempDate = "Để được quyết tâm"
            } 
            if (data.endDate == "Pending TxDOT Construction") {
                tempDate = "Đang chờ xây dựng TxDOT"
            }
            else {
                tempDate = new Date(data.endDate.replace(/-/g, "/")).toLocaleDateString("vi", { month: "long", year: "numeric" })
            }
            setEndDate(tempDate)

            setPrecinct(translation.vietnamese.viewProject.precinct)
            setCountyFundingTitle(translation.vietnamese.viewProject.countyFunding)
        }

        window.addEventListener('languageChange', (e) => {
            console.log('Hi')
        })

        for (let i = 0; i < data.map.length; i++) {
            if (i === 0) {
                _images.push(<div className="carousel-item active" key={i}><img className='d-block w-100 img-fluid' src={data.map[i]} alt="&nbsp;&nbsp;Unavailable" /></div>)
                if (data.map.length > 1) _imageButtons.push(<button type="button" data-bs-target="#projectCarousel" data-bs-slide-to={i} className="active" aria-current="true" aria-label={`Slide ${i + 1}`} key={i} />)
            }
            else {
                _images.push(<div className="carousel-item" key={i}><img className='d-block w-100 img-fluid' src={data.map[i]} alt="&nbsp;&nbsp;Unavailable" /></div>)
                _imageButtons.push(<button type="button" data-bs-target="#projectCarousel" data-bs-slide-to={i} aria-label={`Slide ${i + 1}`} key={i} />)
            }
        }
        console.log(_images);
        setImages(_images)
        setImageButtons(_imageButtons)

        setLoading(false)
    }, [id, props.language]);

    useEffect(() => {
        const project = document.getElementById('project')
        if (project == null) return;
        else {
            window.scrollTo({ left: 0, top: project.getBoundingClientRect().top - 100, behavior: 'smooth' })
        }
    }, [ref])

    if (loading) {
        return (<main className='flex-shrink-0 modular min-vh-100'><Spinner padding={5} /></main>)
    }

    if (error) {
        return (<main className='flex-shrink-0 modular min-vh-100'><div>{notFound}</div></main>)
    }

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item-s active c0 c1">
                            <img src={CarImg} className="d-block w-100" style={{ objectFit: "cover", height: "auto" }} alt="Carousel Img" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div ref={ref} id='project' className='row text-start my-4'>
                        <div className='col-lg-6'>
                            <h1 className='mb-3' style={{ color: "#284E5F" }}> {projectData.title}</h1>

                            <h3>{projectData.location} - {precinct} {precinctId}</h3>
                            <h6 className='ps-4'>{estimatedCompletion}: {endDate}</h6>
                            <h6 className='ps-4'>{status}: {projectStatus}</h6>
                            <h6 className='ps-4'>{countyFundingTitle}: {countyFunding}</h6>
                            <p className='mt-4' style={{ whiteSpace: 'pre-wrap' }}>{projectBody}</p>

                            <h5>{projectContact}</h5>
                            <ul style={{ listStyleType: "none" }}>
                                {projectData.contactName.length !== 0 ?
                                    <> <li>{projectData.contactName} - <em>{ProjectContactTitle}</em></li>
                                        <li>{projectData.contactNumber}</li>
                                        <a href={`mailto:${projectData.contactEmail}?subject=Tarrant County 2021 TBP: ${projectData.title} `}>{projectData.contactEmail}</a> </>
                                    : "To be determined"
                                }
                            </ul>
                        </div>
                        <div className='col-lg-6'>
                            <div className='card border-0'>
                                <div className='card-body'>
                                    <div id="projectCarousel" className="carousel slide" data-bs-ride="true" >
                                        <div className="carousel-indicators">
                                            {imageButtons}
                                        </div>
                                        <div className="carousel-inner">
                                            {images}
                                        </div>
                                        {(projectData.map.length > 1) && (<><button className="carousel-control-prev" type="button" data-bs-target="#projectCarousel" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                            <button className="carousel-control-next" type="button" data-bs-target="#projectCarousel" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button></>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-outline-secondary btn-sm' onClick={() => { navigate('/projects', { state: { fromProjects: true } }) }}>{backButton}</button>
                </div>
            </main>
        </div>
    )
}

import React from 'react'

export default function Spinner(props) {
    const padding = props.padding
    const classData = " d-flex justify-content-center";
    
    return (
        <div className={(padding ==null)? classData:'p-'+padding+classData}>
            <div className="spinner-border" role="status"/>
        </div>
    )
}

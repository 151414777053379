import React, { createRef, useEffect, useState } from 'react'
import CarImg from '../assets/drone shots/Panel Composition - 013.jpg'
import ProjectsData from '../components/Projects.json'
import ProjectListItem from '../components/ProjectListItem'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import translation from '../assets/translation.json'

export default function Projects(props) {
    const [projectLinks, setProjectLinks] = useState([])
    const [sortBy, setSortBy] = useState(['Name',false])
    const [projectMap,setProjectMap] = useState('')
    const [projects,setProjects] = useState('')
    const [name,setName] = useState('')
    const [city,setCity] = useState('')
    const [fundingCategory,setFundingCategory] = useState('')

    const map = (window.innerWidth > 991) ? 
    <iframe title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://www.arcgis.com/apps/dashboards/48299314face43ec891eea4dbc94ee3a"></iframe>
    : <iframe title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://fni.maps.arcgis.com/apps/dashboards/f625f2f5ab3243e7aec0042a7edf48f2"></iframe>
    const arrowDown = <i className="bi bi-caret-down-square float-end"/>
    const arrowUp = <i className="bi bi-caret-up-square float-end"/>
    const ref = createRef();
    const { state } = useLocation()

    const sortByName = (data) => {
        data.sort((a,b) => {
            a = a.title.toLowerCase()
            b = b.title.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }

    const sortByCity = (data) => {
        data.sort((a,b) => {
            if (a.location === b.location) {
                a = a.title.toLowerCase()
                b = b.title.toLowerCase()
                return (a < b) ? -1 : (a > b) ? 1 : 0
            }
            a = a.location.toLowerCase()
            b = b.location.toLowerCase()
            return (a < b) ? -1 : (a > b) ? 1 : 0
        })
    }

    const sortByNameReverse = (data) => {
        data.sort((a,b) => {
            a = a.title.toLowerCase()
            b = b.title.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }

    const sortByCityReverse = (data) => {
        data.sort((a,b) => {
            if (a.location === b.location) {
                a = a.title.toLowerCase()
                b = b.title.toLowerCase()
                return (a > b) ? -1 : (a < b) ? 1 : 0
            }
            a = a.location.toLowerCase()
            b = b.location.toLowerCase()
            return (a > b) ? -1 : (a < b) ? 1 : 0
        })
    }


    useEffect(
        () => {
            if (sortBy[0] === 'Name' && sortBy[1] === false) sortByName(ProjectsData)
            else if (sortBy[0] === 'City' && sortBy[1] === false) sortByCity(ProjectsData)
            else if (sortBy[0] === 'Name' && sortBy[1] === true) sortByNameReverse(ProjectsData)
            else if (sortBy[0] === 'City' && sortBy[1] === true) sortByCityReverse(ProjectsData)
            
            var data = ProjectsData.map((d) => {
                if(props.language === 'Spanish') return (<ProjectListItem title={d.title} id={d.id} key={d.id} location={d.location} startDate={d.startDate} fundingCategory={d.spanish.fundingCategory}/>)
                if(props.language === 'Vietnamese') return (<ProjectListItem title={d.title} id={d.id} key={d.id} location={d.location} startDate={d.startDate} fundingCategory={d.vietnamese.fundingCategory}/>)
                return (<ProjectListItem title={d.title} id={d.id} key={d.id} location={d.location} startDate={d.startDate} fundingCategory={d.english.fundingCategory}/>)
            })
            setProjectLinks(data)
        }, [sortBy,props.language]
    )

    useEffect(()=> {
        if(state == null) return;
        if(state.fromProjects === false) return;
        window.scrollTo({left:0, top: ref.current.offsetTop, behavior: 'smooth'})
    },[state,ref])

    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English'){
            setProjectMap(translation.english.projects.projectMap)
            setProjects(translation.english.projects.projects)
            setName(translation.english.projects.name)
            setCity(translation.english.projects.city)
            setFundingCategory(translation.english.projects.fundingCategory)
        }
        if(lang === 'Spanish'){
            setProjectMap(translation.spanish.projects.projectMap)
            setProjects(translation.spanish.projects.projects)
            setName(translation.spanish.projects.name)
            setCity(translation.spanish.projects.city)
            setFundingCategory(translation.spanish.projects.fundingCategory)
        }
        if(lang === 'Vietnamese'){
            setProjectMap(translation.vietnamese.projects.projectMap)
            setProjects(translation.vietnamese.projects.projects)
            setName(translation.vietnamese.projects.name)
            setCity(translation.vietnamese.projects.city)
            setFundingCategory(translation.vietnamese.projects.fundingCategory)
        }
    },[props.language])

    const handleClick = (sort) => {
        if (sortBy[0] === sort) setSortBy([sortBy[0],!sortBy[1]])
        else if (sortBy[0] !== sort && sort === 'Name') setSortBy(['Name',false])
        else if (sortBy[0] !== sort && sort === 'City') setSortBy(['City',false])
    }

    return (

        <div>
            <Helmet>
                <meta property="og:description" content="A map and list containing all of the projects in Tarrant County's 2021 transportation bond program (2021 TBP)" />
                <meta property="og:title" content="Projects | Tarrant County 2021 Transportation Bond Program" />
                <meta property="og:url" content="https://tarrantcountybond2021.com/projects"/>
            </Helmet>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={CarImg} className="d-block w-100" style={{ objectFit: "cover", height: "auto" }} alt="Projects"/>
                <div className="container">
                    <div className='row my-3'>
                        <div className='col-12'>
                            <h1 style={{ color: "#284E5F" }} className="text-start">{projectMap}</h1>
                            {map}
                        </div>
                    </div>
                    <div ref={ref} className='row my-3 text-start'>
                        <h1 style={{ color: "#284E5F" }} className="text-start">{projects}</h1>
                        <table className='table table-striped table-hover mx-auto' style={{ width:"90%"}}>
                            <thead>
                                <tr>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('Name')}>
                                            <h4>{name} { sortBy[0] === 'Name' ? (sortBy[1] === false) ? arrowDown : arrowUp : "" }</h4>
                                        </div>
                                    </th>
                                    <th className="col-4 hover">
                                        <div className='table-button text-start' onClick={() => handleClick('City')}>
                                            <h4>{city} { sortBy[0] === 'City' ? (sortBy[1] === false) ? arrowDown : arrowUp : "" }</h4>
                                        </div>
                                    </th>
                                    <th className="col-4">
                                        <div className='table-button text-start'>
                                            <h4>{fundingCategory}</h4>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ cursor: "pointer" }} >
                                {projectLinks}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main >
        </div >
    )
}

import { React, useState, useEffect } from "react";
import translation from '../assets/translation.json'

export default function Event(props){
    const [title,setTitle] = useState('')
    const [locationText,setLocationText] = useState('')
    const [startDate,setStartDate] = useState()
    const organization = props.event.organization;
    const description = props.event.description;
    const startTime = new Date(props.event.startDate.replace(/-/g, "/")).toLocaleTimeString("defualt",{hour:"numeric",minute:"numeric"});
    const endTime = new Date(props.event.endDate.replace(/-/g, "/")).toLocaleTimeString("defualt",{hour:"numeric",minute:"numeric"});
    const location = props.event.location;
    const image = props.event.image;
    
    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English'){
            setTitle(props.event.english.title)
            setLocationText(translation.english.events.location)
            setStartDate(new Date(props.event.startDate.replace(/-/g, "/")).toLocaleDateString("en-US",{month:"long",day:"numeric",year:"numeric",}))
        }
        if(lang === 'Spanish'){
            setTitle(props.event.spanish.title)
            setLocationText(translation.spanish.events.location)
            setStartDate(new Date(props.event.startDate.replace(/-/g, "/")).toLocaleDateString("es",{month:"long",day:"numeric",year:"numeric",}))
        }
        if(lang === 'Vietnamese'){
            setTitle(props.event.vietnamese.title)
            setLocationText(translation.vietnamese.events.location)
            setStartDate(new Date(props.event.startDate.replace(/-/g, "/")).toLocaleDateString("vi",{month:"long",day:"numeric",year:"numeric",}))
        }
    },[props.language,props.event])

    return (
        <div className='row py-2 ms-3'>
            <div className='col-lg-4'>
            {(String(props.event.image).length === 0) ? " " : <img className='rounded border border-3' src={image} style={{ objectFit: "cover", width:"100%", maxWidth:"320px"}} alt="Event"/> }
            </div>
            <div className='col-lg-8 py-3'>
                <h3>
                    {title} - <em>{organization}</em>
                </h3>
                <h4>
                    <em>{startDate} ({startTime} - {endTime})</em>
                    <br/>
                </h4>
                <h6>{locationText}: {location}</h6>
                <p>{description}</p>
            </div>
        </div>
    )
}
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ProjectListItem(props) {
    const title = props.title
    const location = props.location
    const id = props.id
    const fundingCategory = props.fundingCategory
    const navigate = useNavigate();
    
    return (
        <tr onClick={() => navigate("/viewprojects/"+id)}>
            <td className='ps-4'>{title}</td>
            <td className='ps-4'>{location}</td>
            <td className='ps-4'>{fundingCategory}</td>
        </tr>
    )
}

import React, { useState, useEffect } from 'react'
import translation from '../assets/translation.json'

export default function Footer(props) {
    const [copyright,setCopyright] = useState('')
    const [bondPolicy,setBondPolicy] = useState('')
    const [managedBy,setManagedBy] = useState('')

    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English'){
            setCopyright(translation.english.footer.copyright)
            setBondPolicy(translation.english.footer.bondPolicy)
            setManagedBy(translation.english.footer.managedBy)
        }
        if(lang === 'Spanish'){
            setCopyright(translation.spanish.footer.copyright)
            setBondPolicy(translation.spanish.footer.bondPolicy)
            setManagedBy(translation.spanish.footer.managedBy)
        }
        if(lang === 'Vietnamese'){
            setCopyright(translation.vietnamese.footer.copyright)
            setBondPolicy(translation.vietnamese.footer.bondPolicy)
            setManagedBy(translation.vietnamese.footer.managedBy)
        }
    },[props.language])

    return (
        <div style={{backgroundColor:"#1D2856"}}>
            <footer className="container py-4 mt-5">
                <div className='row text-light'>
                    <div className='col-lg-9 col-xs-9 text-start'>
                        <p>© {copyright} 2022 | <a href='https://www.tarrantcounty.com/en/transportation/bond-program/transp-imprvements.html' className='text-light'>{bondPolicy}</a> &nbsp; {managedBy}</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

import React, { useState,useEffect } from 'react'
import CarImg from '../assets/drone shots/Panel Composition - 015.jpg'
import { Helmet } from 'react-helmet'
import translation from '../assets/translation.json'
import ReportsData from '../components/Reports.json'
import Report from '../components/Report'

export default function Reports(props) {
    const [reports,setReports] = useState([])
    const language = props.language
    const [reportsTitle,setReportsTitle] = useState('')

    useEffect(() => {
        const lang = localStorage.getItem('language')
        var data = ReportsData.map((r,i) => <Report key={i} name={r.name} path={r.path}/>)
        setReports(data)
        if(lang === 'English'){
            setReportsTitle(translation.english.reports.reports)
        }
        if(lang === 'Spanish'){
            setReportsTitle(translation.spanish.reports.reports)
        }
        if(lang === 'Vietnamese'){
            setReportsTitle(translation.vietnamese.reports.reports)
        }
    },[language])

    return (
        <div>
            <Helmet>
                <meta property="og:description" content="Project reports for Tarrant County's 2021 transportation bond program (2021 TBP)" />
                <meta property="og:title" content="Reports | Tarrant County 2021 Transportation Bond Program" />
                <meta property="og:url" content="https://tarrantcountybond2021.com/reports"/>
            </Helmet>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={CarImg} className="d-block w-100" style={{ objectFit: "cover", height: "auto" }} alt="Reports"/>
                <div className='container'>
                    <div className='text-start my-3'>
                        <h1 style={{ color: "#284E5F" }} className="text-start">{reportsTitle}</h1>
                    </div>
                    <table className='table table-secondary table-hover mx-auto mt-4' style={{ width:"90%"}}>
                        <tbody style={{ cursor: "pointer" }}>
                            {reports}
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    )
}

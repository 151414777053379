import React, { useEffect, useState } from 'react'
import CarImg from '../assets/drone shots/Panel Composition - 003.jpg'
import PrecintMap from '../assets/PrecinctMap.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import translation from '../assets/translation.json'

export default function Home(props) {
    const language = props.language
    const [title,setTitle] = useState('')
    const [body1,setBody1] = useState('')
    const [body2,setBody2] = useState('')
    const [body3,setBody3] = useState('')
    const [events,setEvents] = useState('')
    const [projects,setProjects] = useState('')
    const [reports,setReports] = useState('')

    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English') {
            setTitle(translation.english.home.title)
            setBody1(translation.english.home.body1)
            setBody2(translation.english.home.body2)
            setBody3(translation.english.home.body3)
            setEvents(translation.english.home.events)
            setProjects(translation.english.home.projects)
            setReports(translation.english.home.reports)
        }
        if(lang === 'Spanish') {
            setTitle(translation.spanish.home.title)
            setBody1(translation.spanish.home.body1)
            setBody2(translation.spanish.home.body2)
            setBody3(translation.spanish.home.body3)
            setEvents(translation.spanish.home.events)
            setProjects(translation.spanish.home.projects)
            setReports(translation.spanish.home.reports)
        }
        if(lang === 'Vietnamese') {
            setTitle(translation.vietnamese.home.title)
            setBody1(translation.vietnamese.home.body1)
            setBody2(translation.vietnamese.home.body2)
            setBody3(translation.vietnamese.home.body3)
            setEvents(translation.vietnamese.home.events)
            setProjects(translation.vietnamese.home.projects)
            setReports(translation.vietnamese.home.reports)
        }
    },[language])

    return (
        <div>
            <Helmet>
                <meta property="og:description" content="Tarrant County aims to improve its transportation system by implementing numerous projects through its 2021 transportation bond program (2021 TBP). We invite you to learn more about each project." />
                <meta property="og:title" content="Home | Tarrant County 2021 Transportation Bond Program" />
                <meta property="og:url" content="https://tarrantcountybond2021.com/"/>
            </Helmet>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={CarImg} className="d-block w-100" style={{ objectFit: "cover", height: "auto" }} alt="Home"/>
                <div className="container">
                    <div className='row text-start mx-5 mt-4'>
                        <div className='col-lg-8'>
                            <h1 style={{ color: "#284E5F" }}>{title}</h1>
                            <p> 
                                {body1}
                                <br/><br/>
                                {body2}
                                <br/><br/>
                                {body3}
                                <br/><br/>
                            </p>
                            <div className='row text-center mt-4'>
                                <div className='col-lg-4'>
                                    <Link to="/events" ><i className="bi bi-calendar-check" style={{ fontSize: '70px', color: '#1D2856' }}/></Link>
                                    <h5 className='my-2' style={{ color: "#053188" }}>{events}</h5>
                                </div>
                                <div className='col-lg-4'>
                                    <Link to="/projects"><i className="bi bi-folder-fill" style={{ fontSize: '70px', color: '#1D2856' }}/></Link>
                                    <h5 className='my-2' style={{ color: "#053188" }}>{projects}</h5>
                                </div>
                                <div className='col-lg-4'>
                                    <a href="/reports"><i className="bi bi-graph-up-arrow" style={{ fontSize: '70px', color: '#1D2856' }}/></a>
                                    <h5 className='my-2' style={{ color: "#053188" }}>{reports}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='card border border-2'>
                                <div className='card-body text-center'>
                                    <Link to="/projects">
                                        <img className='img-fluid' src={PrecintMap} alt="Map"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

import { React, useState, useEffect } from 'react'
import CarImg from '../assets/drone shots/Panel Composition - 006.jpg'
import EventsList from "../components/Events.json";
import Event from '../components/Event'
import { Helmet } from 'react-helmet';
import translation from '../assets/translation.json'

export default function Events(props) {
    const language = props.language
    const [upcomingEventsList,setUpcomingEventsList] = useState([]);
    const [pastEventsList,setPastEventsList] = useState([]);
    const [upcomingEvents,setUpcomingEvents] = useState('')
    const [pastEvents,setPastEvents] = useState('')
    const [noUpcomingEvents,setNoUpcomingEvents] = useState('')
    const [noPastEvents,setNoPastEvents] = useState('')

    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English') {
            setUpcomingEvents(translation.english.events.upcomingEvents)
            setPastEvents(translation.english.events.pastEvents)
            setNoUpcomingEvents(translation.english.events.noUpcomingEvents)
            setNoPastEvents(translation.english.events.noPastEvents)
        }
        if(lang === 'Spanish'){
            setUpcomingEvents(translation.spanish.events.upcomingEvents)
            setPastEvents(translation.spanish.events.pastEvents)
            setNoUpcomingEvents(translation.spanish.events.noUpcomingEvents)
            setNoPastEvents(translation.spanish.events.noPastEvents)
        }
        if(lang === 'Vietnamese'){
            setUpcomingEvents(translation.vietnamese.events.upcomingEvents)
            setPastEvents(translation.vietnamese.events.pastEvents)
            setNoUpcomingEvents(translation.vietnamese.events.noUpcomingEvents)
            setNoPastEvents(translation.vietnamese.events.noPastEvents)
        }
        EventsList.sort((a,b) => {
            return new Date(a.startDate.replace(/-/g, "/")) - new Date(b.startDate.replace(/-/g, "/"));
        });
        var past = [], upcoming =[];
        
        EventsList.forEach(e => {
            (new Date(e.endDate.replace(/-/g, "/")) > (new Date())) ? upcoming.push(<Event event={e} key={e.id} language={language}/>) : past.push(<Event event={e} key={e.id} language={language}/>)
        });
        
        setUpcomingEventsList(upcoming);
        setPastEventsList(past);
    },[language]);

    return (
        <div>
            <Helmet>
                <meta property="og:description" content="Events reguarding projects in Tarrant County's 2021 transportation bond program (2021 TBP)" />
                <meta property="og:title" content="Events | Tarrant County 2021 Transportation Bond Program" />
                <meta property="og:url" content="https://tarrantcountybond2021.com/events"/>
            </Helmet>
            <main className='flex-shrink-0 modular min-vh-100'>
                <img src={CarImg} className="d-block w-100" style={{ objectFit: "cover", height: "auto" }} alt="Events"/>
                <div className="container">
                    <div className='row text-start mt-4'>
                        <h1 className='mb-4' style={{ color: "#284E5F" }}>{upcomingEvents}</h1>
                        {(upcomingEventsList.length === 0) ? <h3 className="text-center text-secondary"><em>{noUpcomingEvents}</em></h3> : upcomingEventsList}
                    </div>
                    <div className='row text-start mt-4'>
                        <h1 style={{ color: "#284E5F" }}>{pastEvents}</h1>
                        {(pastEventsList.length === 0) ? <h3 className="text-center text-secondary"><em>{noPastEvents}</em></h3> : pastEventsList}
                    </div>
                </div>
            </main>
        </div>
    )
}

import React, { useState, useEffect } from "react"
import translation from '../assets/translation.json'

export default function Page404(props) {
    const language = props.language
    const [notFound,setNotFound] = useState('')

    useEffect(() => {
        const lang = localStorage.getItem('language')
        if(lang === 'English'){
            setNotFound(translation.english.notFound)
        }
        if(lang === 'Spanish'){
            setNotFound(translation.spanish.notFound)
        }
        if(lang === 'Vietnamese'){
            setNotFound(translation.vietnamese.notFound)
        }
    },[language])

    return (
        <main className='flex-shrink-0 modular min-vh-100'>
            <div className="text-center p-4">{notFound}</div>
        </main>
    )
}